/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 glowing.glb --transform 
Files: glowing.glb [45.13KB] > /Users/totsukatomofumi/Desktop/medieval/glowing-transformed.glb [4.8KB] (89%)
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import glowingModel from "./glowing-transformed.glb";

function Glowing(props) {
  const { nodes, materials } = useGLTF(glowingModel);
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.obj_house_1_material_planks_02262.geometry}
        material={materials["material_planks.003"]}
        position={[-6.344, 4.593, 6.442]}
        rotation={[-1.523, -0.001, 1.57]}
        scale={[0.091, 0.065, 0.999]}
      />
      <mesh
        geometry={nodes.obj_house_1_material_window_and_steel_0331.geometry}
        material={materials["material_window_and_steel.003"]}
        position={[-7.827, 7.892, 5.825]}
        rotation={[-1.52, 0, Math.PI / 2]}
        scale={[0.091, 0.065, 0.999]}
      />
    </group>
  );
}

useGLTF.preload(glowingModel);

export default Glowing;
